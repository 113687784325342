import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "w-full mt-8 border-b border-gray-600 pb-24" }
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = {
  key: 0,
  class: "text-center my-32"
}
const _hoisted_4 = {
  key: 0,
  class: "w-full flex justify-center mt-24"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Poster = _resolveComponent("Poster")!
  const _component_Plus = _resolveComponent("Plus")!
  const _component_ElIcon = _resolveComponent("ElIcon")!
  const _component_ElButton = _resolveComponent("ElButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (poster, index) => {
        return (_openBlock(), _createBlock(_component_Poster, {
          key: poster.uuid,
          poster: poster,
          index: index,
          "can-remove": _ctx.modelValue.length > 1,
          disabled: _ctx.isPostersDisabled,
          "client-email": _ctx.clientEmail,
          "is-image-required": _ctx.isImageRequired,
          "is-video-required": _ctx.isVideoRequired,
          "parent-view": _ctx.parentView,
          "width-for-aspect-ratio": _ctx.widthForAspectRatio,
          "height-for-aspect-ratio": _ctx.heightForAspectRatio,
          dpi: _ctx.dpi,
          class: "mb-32 last:mb-0",
          onOnRemove: ($event: any) => (_ctx.onPosterRemove(poster)),
          onUpdateFileId: _ctx.onPosterFileIdUpdate,
          onUploadArAnchor: ($event: any) => (_ctx.onUploadArAnchor(poster)),
          onUpdateArProperties: ($event: any) => (_ctx.onUpdateArProperties(poster)),
          onRefetchData: _ctx.onRefetchData
        }, null, 8, ["poster", "index", "can-remove", "disabled", "client-email", "is-image-required", "is-video-required", "parent-view", "width-for-aspect-ratio", "height-for-aspect-ratio", "dpi", "onOnRemove", "onUpdateFileId", "onUploadArAnchor", "onUpdateArProperties", "onRefetchData"]))
      }), 128)),
      (!_ctx.modelValue || _ctx.modelValue.length === 0)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, " No posters found "))
        : _createCommentVNode("", true)
    ]),
    (_ctx.canAdd)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_ElButton, {
            type: "primary",
            onClick: _ctx.onAdd
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ElIcon, {
                class: "el-icon--left",
                size: "20"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Plus)
                ]),
                _: 1
              }),
              _createTextVNode(" Add poster ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true)
  ]))
}