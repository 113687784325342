
import { defineComponent, PropType } from 'vue'
import { ElButton, ElIcon } from 'element-plus'
import { Plus } from '@element-plus/icons-vue'

import { ICreatePosterForm } from '@/types'

import Poster from './Poster.vue'

export default defineComponent({
  name: 'Posters',

  components: {
    Poster,
    ElButton,
    ElIcon,
    Plus,
  },

  props: {
    modelValue: {
      required: true,
      type: Array as PropType<ICreatePosterForm[]>,
    },

    min: {
      type: Number as PropType<number>,
      default: 1,
    },
    max: {
      type: Number as PropType<number>,
      default: 1,
    },
    widthForAspectRatio: {
      type: Number as PropType<number>,
      default: 1,
    },
    heightForAspectRatio: {
      type: Number as PropType<number>,
      default: 1,
    },
    dpi: {
      type: Number as PropType<number>,
      default: null,
    },
    canAdd: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isPostersDisabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    clientEmail: {
      type: String as PropType<string>,
      default: '',
    },
    isImageRequired: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    isVideoRequired: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    parentView: {
      type: String as PropType<'create' | 'update'>,
      default: 'create',
    },
  },

  emits: [
    'update:modelValue',
    'addPoster',
    'removePoster',
    'updatePosterFileId',
    'uploadArAnchor',
    'refetchData',
    'updateArProperties',
  ],

  setup(_, { emit }) {
    const onAdd = (): void => {
      emit('addPoster')
    }

    const onPosterRemove = (poster: ICreatePosterForm) => {
      emit('removePoster', poster)
    }

    const onPosterFileIdUpdate = (posterData: {
      uuid: string
      fileId: number
      type: 'image' | 'video'
    }) => {
      emit('updatePosterFileId', posterData)
    }

    const onUploadArAnchor = (poster: ICreatePosterForm) => {
      emit('uploadArAnchor', poster)
    }

    const onUpdateArProperties = (poster: ICreatePosterForm) => {
      emit('updateArProperties', poster)
    }

    const onRefetchData = () => {
      emit('refetchData')
    }

    return {
      onAdd,
      onPosterRemove,
      onPosterFileIdUpdate,
      onUploadArAnchor,
      onRefetchData,
      onUpdateArProperties,
    }
  },
})
