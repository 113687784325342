
import { defineComponent, PropType, computed, h, ref } from 'vue'
import { ElMessageBox, ElNotification, ElButton } from 'element-plus'
import { Delete } from '@element-plus/icons-vue'

import { IPosterFileUpload, EFileOptimizationStatus } from '@/types'
import { useOrders } from '@/core/compositions'
import { apiErrorNotification } from '@/core/helpers'

import PosterFileUpload from './PosterFileUpload.vue'

export default defineComponent({
  name: 'Poster',

  components: {
    PosterFileUpload,
    ElButton,
  },

  props: {
    poster: {
      required: true,
      type: Object as PropType<IPosterFileUpload>,
    },

    index: {
      type: Number as PropType<number>,
    },
    canRemove: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    clientEmail: {
      type: String as PropType<string>,
      default: '',
    },
    isImageRequired: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    isVideoRequired: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    parentView: {
      type: String as PropType<'create' | 'update'>,
      default: 'create',
    },
    widthForAspectRatio: {
      type: Number as PropType<number>,
      default: 1,
    },
    heightForAspectRatio: {
      type: Number as PropType<number>,
      default: 1,
    },
    dpi: {
      type: Number as PropType<number>,
      default: null,
    },
  },

  emits: [
    'onRemove',
    'updateFileId',
    'onRegenerateArAnchore',
    'uploadArAnchor',
    'refetchData',
    'updateArProperties',
  ],

  setup(props, { emit }) {
    const { regeneratePosterArAnchore } = useOrders()

    const loading = ref(false)

    const posterIndex = computed(() => {
      return (props?.index || 0) + 1
    })

    const optimisationStatusLabel = computed(() => {
      switch (props.poster?.videoOptimizationStatus) {
        case EFileOptimizationStatus.New:
          return 'Not optimized yet (New)'
        case EFileOptimizationStatus.Busy:
          return 'Optimization in progress (Busy)'
        case EFileOptimizationStatus.Done:
          return 'Optimized (Done)'
        case EFileOptimizationStatus.Error:
          return 'Optimisation error (Error) *'
        default:
          return ''
      }
    })

    const onRemove = async () => {
      const confirm = await ElMessageBox.confirm('Warning', {
        message: h('span', null, [
          'Are you sure you want to remove ',
          h('span', { class: 'font-bold' }, `poster ${posterIndex.value}?`),
        ]),
        confirmButtonText: 'Remove',
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: 'Cancel',
        type: 'warning',
      })

      if (!confirm) {
        return false
      }

      emit('onRemove', props.index)
    }

    const onUpdateImageFileId = (fileId: number) => {
      emit('updateFileId', {
        uuid: props.poster.uuid,
        fileId: fileId,
        type: 'image',
      })
    }
    const onUpdateVideoFileId = (fileId: number) => {
      emit('updateFileId', {
        uuid: props.poster.uuid,
        fileId,
        type: 'video',
      })
    }

    const onRegenerateArAnchore = async () => {
      const confirm = await ElMessageBox.confirm('Warning', {
        message: h('span', null, [
          'Are you sure you want to generate (or re-generate) AR Anchore for this image?',
        ]),
        confirmButtonText: 'Generate',
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: 'Cancel',
        type: 'warning',
      })

      if (!confirm) {
        return false
      }

      loading.value = true

      try {
        await regeneratePosterArAnchore(props.poster.id as number)

        ElNotification({
          message: 'Poster AR anchore successfully generated!',
          type: 'success',
        })

        emit('refetchData')
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)
      } finally {
        loading.value = false
      }
    }

    const onUploadArAnchor = () => {
      emit('uploadArAnchor')
    }

    const onUpdateArProperties = () => {
      emit('updateArProperties')
    }

    return {
      loading,
      Delete,
      posterIndex,
      onRemove,
      onUpdateImageFileId,
      onUpdateVideoFileId,
      onRegenerateArAnchore,
      optimisationStatusLabel,
      onUploadArAnchor,
      onUpdateArProperties,
    }
  },
})
