
import { defineComponent, ref, PropType, computed } from 'vue'
import {
  ElIcon,
  UploadFile,
  UploadProps,
  ElMessageBox,
  ElTooltip,
} from 'element-plus'
import {
  UploadFilled,
  Delete,
  SuccessFilled,
  Download,
  Crop,
  Camera,
} from '@element-plus/icons-vue'
import calculateAspectRatio from 'calculate-aspect-ratio'
import VuePictureCropper, { cropper } from 'vue-picture-cropper'

import { TPosterFileUpload } from '@/types'
import { apiErrorNotification } from '@/core/helpers'
import { filesService } from '@/services'
import { acceptFileFormats, maxFileSizesInMb } from '@/config'

export default defineComponent({
  name: 'PosterFileUpload',

  components: {
    UploadFilled,
    SuccessFilled,
    ElIcon,
    ElTooltip,
    VuePictureCropper,
  },

  props: {
    posterId: {
      type: Number as PropType<number>,
      default: null,
    },

    type: {
      type: String as PropType<TPosterFileUpload>,
      default: 'image',
    },

    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    clientEmail: {
      type: String as PropType<string>,
      default: '',
    },

    fileId: {
      type: Number as PropType<number>,
      default: null,
    },
    fileUrl: {
      type: String as PropType<string>,
      default: null,
    },

    cropAfterUpload: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    widthForAspectRatio: {
      type: Number as PropType<number>,
      default: 1,
    },
    heightForAspectRatio: {
      type: Number as PropType<number>,
      default: 1,
    },
    dpi: {
      type: Number as PropType<number>,
      default: null,
    },
  },

  emits: ['updateFileId', 'regenerateArAnchore'],

  setup(props, { emit }) {
    const activeView = ref<'upload' | 'crop'>('upload')

    const elUploadRef = ref()
    const formUploadFile = ref<UploadFile | null>(null)

    const loading = ref(false)
    const uploadingError = ref(false)
    const uploadController = ref<AbortController | null>(null)

    const isCropperLoaded = ref(false)
    const isImageCropped = ref(false)
    const imageDpi = ref<number | null>(null)
    const uploadFileUrl = ref<string | null>(null)

    const typeFileAcceptFormats = computed(() => {
      return props.type === 'image'
        ? acceptFileFormats.image.join(',')
        : acceptFileFormats.video.join(',')
    })

    const isFile = computed(() => {
      return (
        formUploadFile.value ||
        ((props.fileUrl || uploadFileUrl.value) && props.fileId)
      )
    })

    const aspectRatio = computed(() => {
      const ratio = calculateAspectRatio(
        props.widthForAspectRatio,
        props.heightForAspectRatio,
      )

      const widthRatioStr = ratio.split(':')[0]
      const widthRatio = !isNaN(parseInt(widthRatioStr))
        ? parseInt(widthRatioStr)
        : 1

      const heightRatioStr = ratio.split(':')[1]
      const heightRatio = !isNaN(parseInt(heightRatioStr))
        ? parseInt(heightRatioStr)
        : 1

      return widthRatio / heightRatio
    })

    const imageDPIErrorMessageVisible = computed(() => {
      return (
        props.type === 'image' &&
        isFile.value &&
        isImageCropped.value &&
        imageDpi.value &&
        props.dpi &&
        imageDpi.value < props.dpi
      )
    })

    const handleFileChange: UploadProps['onChange'] = (file) => {
      onRemoveUploadFile()

      formUploadFile.value = file

      uploadFile(file)
    }

    const onRemoveUploadFile = async (showNotification?: boolean) => {
      if (showNotification) {
        const confirm = await ElMessageBox.confirm('Warning', {
          message: 'Are you sure you want to remove this file?',
          confirmButtonText: 'Remove',
          confirmButtonClass: 'el-button--danger',
          cancelButtonText: 'Cancel',
          type: 'warning',
        })

        if (!confirm) {
          return false
        }
      }

      loading.value = false
      formUploadFile.value = null
      uploadFileUrl.value = null
      uploadingError.value = false
      uploadController.value?.abort()
      elUploadRef.value.clearFiles()
      emit('updateFileId', null)
    }

    const uploadFile = async (file: any, isCropped = false) => {
      loading.value = true

      try {
        uploadController.value = new AbortController()

        const config = {
          signal: uploadController.value?.signal,
        }

        const formDataName = props.type === 'image' ? 'image' : 'video'
        const fileFormData = new FormData()
        fileFormData.append(formDataName, file.raw as Blob)

        const serviceMethodName =
          props.type === 'image' ? 'uploadImage' : 'uploadVideo'
        const { id, url } = await filesService[serviceMethodName](
          fileFormData,
          'orders',
          config,
        )

        uploadFileUrl.value = url

        emit('updateFileId', id)

        if (props.cropAfterUpload && !isCropped) {
          isCropperLoaded.value = false
          imageDpi.value = null
          activeView.value = 'crop'
        }

        if (isCropped) {
          isImageCropped.value = true

          getImageDPI()
        }
      } catch (error: any) {
        console.error(error)
        if (error.code !== 'ERR_CANCELED') {
          uploadingError.value = true
          apiErrorNotification(error)
        }
      } finally {
        loading.value = false
      }
    }

    const downloadFile = () => {
      const anchor = document.createElement<'a'>('a')
      anchor.href = props.fileUrl
      anchor.download = `poster_${props.type}`

      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
    }

    const regenerateArAnchore = () => {
      emit('regenerateArAnchore')
    }

    const onCropperReady = () => {
      isCropperLoaded.value = true
    }

    const clearCropper = () => {
      isCropperLoaded.value = false
      isImageCropped.value = false
      imageDpi.value = null
      activeView.value = 'upload'

      onRemoveUploadFile()
    }

    const resetCropper = () => {
      if (!cropper) return
      cropper.reset()
    }

    const cropImage = async () => {
      if (!cropper) return

      const blob = await cropper.getBlob()
      if (!blob) return

      const fileFormat = blob.type?.split('/').pop()
      const fileUrlTemp = uploadFileUrl.value || props.fileUrl
      const fileName = getFileNameFromUrl(fileUrlTemp)

      const fileNameWithFormat =
        formUploadFile.value?.raw?.name || fileName || ''
      const fileNameWithoudFormat = fileNameWithFormat.split('.')?.[0] || ''
      const newFileName = fileNameWithoudFormat + '.' + fileFormat

      const cropperFile = new File(
        [blob],
        newFileName || 'new-image.' + fileFormat || '',
        {
          type: blob.type,
          // size: blob.size,
        },
      )

      isCropperLoaded.value = false
      isImageCropped.value = false
      imageDpi.value = null
      activeView.value = 'upload'

      uploadFile(
        {
          name: cropperFile.name,
          size: cropperFile.size,
          raw: cropperFile,
        },
        true,
      )
    }

    const openCropView = () => {
      isCropperLoaded.value = false
      isImageCropped.value = false
      imageDpi.value = null
      activeView.value = 'crop'
    }

    const getFileNameFromUrl = (url: string) => {
      const newUrl = new URL(url)
      const urlPathArr = newUrl.pathname.split('/')
      if (urlPathArr.length) {
        return urlPathArr.pop()
      }

      return null
    }

    const getImageDPI = () => {
      if (
        (!props.fileUrl || !uploadFileUrl.value) &&
        props.type !== 'image' &&
        props.dpi
      ) {
        return
      }

      const imageUrl = props.fileUrl || uploadFileUrl.value
      const image = new Image()
      image.onload = function () {
        const imageWidth = (this as any)?.width
        if (imageWidth) {
          imageDpi.value = imageWidth / props.widthForAspectRatio
          imageDpi.value = Math.round(imageDpi.value)
        }
      }
      image.src = imageUrl as string
    }

    return {
      elUploadRef,
      formUploadFile,
      isFile,
      handleFileChange,
      Delete,
      Download,
      Crop,
      Camera,
      onRemoveUploadFile,
      typeFileAcceptFormats,
      maxFileSizesInMb,
      uploadingError,
      loading,
      downloadFile,
      regenerateArAnchore,
      activeView,
      clearCropper,
      resetCropper,
      cropImage,
      onCropperReady,
      aspectRatio,
      isCropperLoaded,
      isImageCropped,
      uploadFileUrl,
      openCropView,
      imageDpi,
      imageDPIErrorMessageVisible,
    }
  },
})
